import { lazy } from "react";

import Role from "../constants/Role";

import { FEATURES } from "./features";

const BulkPhotoManagement = lazy(() => import("../pages/BulkPhotoManagement"));
const IndividualPhotoManagement = lazy(
    () => import("../pages/IndividualPhotoManagement"),
);
const VanityRedirects = lazy(() => import("../pages/VanityRedirects"));
const VsmReporting = lazy(() => import("../pages/VsmReporting"));
const ABTestRegistration = lazy(() => import("../pages/ABTestRegistration"));
const MarketingUploads = lazy(() => import("../pages/MarketingUploads"));
const P2pRedirects = lazy(() => import("../pages/P2pRedirects"));
const PlayerMusic = lazy(() => import("../pages/PlayerMusic"));

export const apps = [
    {
        url: "/bulk-photo-management/people",
        name: "Bulk Photo Management",
        enabled: FEATURES.BULK_PLAYER_PHOTOS,
        roles: [Role.BULK_PHOTO_ALL, Role.BULK_PHOTO_MILB, Role.BULK_PHOTO], // PUBSRV-2356 remove Role.BULK_PHOTO after Okta cleanup
        page: BulkPhotoManagement,
    },
    {
        url: "/marketing-uploads",
        name: "Marketing Uploads",
        enabled: FEATURES.MARKETING,
        roles: [Role.MARKETING],
        page: MarketingUploads,
    },
    {
        url: "/redirects/vanity",
        name: "Vanity Redirects",
        enabled: FEATURES.REDIRECTS,
        roles: [Role.REDIRECTS],
        page: VanityRedirects,
    },
    {
        url: "/individual-photo-management/people",
        name: "Individual Photo Management",
        enabled: FEATURES.PLAYER_PHOTOS,
        roles: [Role.PHOTO_ALL, Role.PHOTO_MILB, Role.PHOTO], // PUBSRV-2356 remove Role.PHOTO after Okta cleanup
        page: IndividualPhotoManagement,
    },
    {
        url: "/game-changer",
        name: "Game Changer (AB)",
        enabled: FEATURES.AB_TEST_REGISTRATION,
        roles: [Role.AB],
        page: ABTestRegistration,
    },
    {
        url: "/vsm-reporting",
        name: "VSM Reporting",
        enabled: FEATURES.VSM_REPORTING,
        roles: [Role.VSM_REPORTING],
        page: VsmReporting,
    },
    {
        url: "/p2p-redirects",
        name: "P2P Redirects",
        enabled: FEATURES.REDIRECTS,
        roles: [Role.REDIRECTS],
        page: P2pRedirects,
    },
    {
        url: "/player-music",
        name: "Player Music",
        enabled: FEATURES.PLAYER_MUSIC,
        roles: [Role.PLAYER_MUSIC],
        page: PlayerMusic,
    },
];
