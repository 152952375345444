enum Role {
    ADMIN = "FiveTool-Admin",
    REDIRECTS = "FiveTool-Redirects",
    MARKETING = "FiveTool-Marketing",
    PHOTO = "FiveTool-Photo", // PUBSRV-2356 remove after rename
    PHOTO_ALL = "FiveTool-Photo-All", // PUBSRV-2356
    BULK_PHOTO = "FiveTool-Photo-Bulk-Uploads", // PUBSRV-2356 remove after rename
    BULK_PHOTO_ALL = "FiveTool-Photo-Bulk-Uploads-All", // PUBSRV-2356
    BULK_PHOTO_MILB = "FiveTool-Photo-Bulk-Uploads-MiLB",
    PHOTO_MILB = "FiveTool-Photo-MiLB",
    FORGE = "FiveTool-Forge",
    AB = "FiveTool-AB",
    VSM_REPORTING = "FiveTool-VSM-Reporting",
    PLAYER_MUSIC = "FiveTool-Player-Music",
    P2P = "P2P-Redirects",
    USERS = "FiveTool-Users",
}

export default Role;
